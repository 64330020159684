/* Share */

.fancybox-share {
    background: #f4f4f4;
    border-radius: 3px;
    max-width: 90%;
    padding: 30px;
    text-align: center;
}

.fancybox-share h1 {
    color: #222;
    font-size: 35px;
    font-weight: 700;
    margin: 0 0 20px 0;
}

.fancybox-share p {
    margin: 0;
    padding: 0;
}

.fancybox-share__button {
    border: 0;
    border-radius: 3px;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    margin: 0 5px 10px 5px;
    min-width: 130px;
    padding: 0 15px;
    text-decoration: none;
    transition: all .2s;
    user-select: none;
    white-space: nowrap;
}

.fancybox-share__button:visited,
.fancybox-share__button:link {
    color: #fff;
}

.fancybox-share__button:hover {
    text-decoration: none;
}

.fancybox-share__button--fb {
    background: #3b5998;
}

.fancybox-share__button--fb:hover {
    background: #344e86;
}

.fancybox-share__button--pt {
    background: #bd081d;
}

.fancybox-share__button--pt:hover {
    background: #aa0719;
}

.fancybox-share__button--tw {
    background: #1da1f2;
}

.fancybox-share__button--tw:hover {
    background: #0d95e8;
}

.fancybox-share__button svg {
    height: 25px;
    margin-right: 7px;
    position: relative;
    top: -1px;
    vertical-align: middle;
    width: 25px;
}

.fancybox-share__button svg path {
    fill: #fff;
}

.fancybox-share__input {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #d7d7d7;
    border-radius: 0;
    color: #5d5b5b;
    font-size: 14px;
    margin: 10px 0 0 0;
    outline: none;
    padding: 10px 15px;
    width: 100%;
}