.section_tests {
    background-color: #2b3a42; 
    padding-bottom: 50px;
    height: auto; 
}
.container__tests {
    @include tablets {
        height: auto;
        padding: 0 16px 40px 16px;
    }
}
.title_adaptive {
    @include phones {
       font-size: 30px;
    }
    @include phones360 {
       font-size: 25px;
    }
}
.wrap__shrt__text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
}
.wrap_test {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 20px auto 0;
    position: relative;
    @include tablets {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
.tests__question {
    font-family: 'robotoCondensedBold';
    font-size: 30px;
    color: white;
    text-align: center;
    margin: 30px 0;
    @include phones {
        font-size: 22px;
     }
}
.tests__right {
    width: 50%;
    height: 300px;
    @include tablets {
        width: 100%;
    }
    
}
.tests__left  {
    width: 50%;
    height: 300px;
    @include tablets {
        width: 100%;
    }
}
.tests__shrt-text {
    font-family: 'robotoCondensedRegular';
    width: 80%;
    margin: 40px auto 0;
    // color: rgb(101, 146, 169);
    color: #d6c578;
    font-size: 23px;
}
.shrt__text {
    font-family: 'robotoCondensedRegular';
    width: 100%;
    margin: 0 auto;
    margin-top: 17px;
    margin-bottom: 20px;

}
.shrt__title {
    font-family: 'robotoCondensedBold';
    font-size: 27px;
    width: 80%;
    margin: 20px auto 0;
    color: $red_shade;
    @include phones {
        font-size: 22px;
     }
}
.shrt__txt {
    font-family: 'robotoCondensedRegular';
    width: 80%;
    margin: 0 auto;
    color: rgb(101, 146, 169);
    font-size: 16px;

    span {
        color: $red_shade;
        font-size: 20px; 
    }
}

.tests__item {
    font-family: 'Roboto';
    font-size: 18px;
    color: white;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 3px solid rgb(81, 107, 120);
    width: 40%;
    height: 45px;
    margin: 20px auto 0;
    cursor: pointer;

  -webkit-box-shadow: 0px 0px 7px -5px rgba(0,0,0,0.79);
-moz-box-shadow: 0px 0px 7px -5px rgba(0,0,0,0.79);
box-shadow: 0px 0px 7px -5px rgba(0,0,0,0.79);

    @include desktop {
        width: 65%;
    }
    @include tablets {
        width: 90%;
    }

}

.item-num {
    width: 50px;
    height: 100%;
    font-family: 'Roboto';
    color:  white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.item-pls {
    width: 50px;
    height: 100%;
    position: relative;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
        background: url('../images/plus.png') no-repeat;
        background-size: cover;
    }
    
}
.item-del {
    width: 50px;
    height: 100%;
    
   
    position: relative;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
        background: url('../images/del.png') no-repeat;
        background-size: cover;
    }

    
}

.wrap-result {
    display: flex;
    justify-content: center;
    @include phones {
        flex-direction: column;
        align-items: center;
    }
}
.res-txt {
    font-family: 'robotoCondensedBold';
    font-size: 20px; 
    color: rgb(101, 146, 169);
}
.res-dspl-true,
.res-dspl-false{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    width: 120px;
    height: 30px;
    border-radius: 4px;
    font-family: 'robotoCondensedRegular';
    font-size: 18px;
    color: white;
    @include phones {
        margin-top: 10px;
    }
}
.res-dspl-true {
    background-color: $dark_green;
}
.res-dspl-false {
    background-color: $red_shade;
   
}
.dspl-disable {
    position: relative;
    
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;  
        background-color: #3f3f3f;
        opacity: 0.8;
        border-radius: 4px;
    }
   
}
.tests__list-right,
.tests__list {
    width: 100%;
    height: 100%;
}
.block__button-test {
    margin: 0 auto;
    width: 250px;
    margin-top: 20px;
     
}
.button__link {
    width: 250px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: 'Roboto-medium';
    text-decoration: none;
    text-transform: uppercase;
     background-color: #00ab97;
    /* IE9, iOS 3.2+ */
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMCUiIHkyPSIxMDAlIj48c3RvcCBzdG9wLWNvbG9yPSIjMDA5Njg4IiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMCIvPjxzdG9wIHN0b3AtY29sb3I9IiMwMGJmYTUiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzAwYmZhNSIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjAiLz48c3RvcCBzdG9wLWNvbG9yPSIjMDA5Njg4IiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMSIvPjwvbGluZWFyR3JhZGllbnQ+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCN2c2dnKSIgLz48L3N2Zz4=);
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%,color-stop(0, rgb(0, 150, 136)),color-stop(0, rgb(0, 191, 165)),color-stop(0, rgb(0, 191, 165)),color-stop(1, rgb(0, 150, 136)));
    /* Android 2.3 */
    background-image: -webkit-repeating-linear-gradient(top,rgb(0, 150, 136) 0%,rgb(0, 191, 165) 0%,rgb(0, 191, 165) 0%,rgb(0, 150, 136) 100%);
    /* IE10+ */
    background-image: repeating-linear-gradient(to bottom,rgb(0, 150, 136) 0%,rgb(0, 191, 165) 0%,rgb(0, 191, 165) 0%,rgb(0, 150, 136) 100%);
    background-image: -ms-repeating-linear-gradient(top,rgb(0, 150, 136) 0%,rgb(0, 191, 165) 0%,rgb(0, 191, 165) 0%,rgb(0, 150, 136) 100%);
    
    &:hover {
       
    background-color: #00ab97;
    /* IE9, iOS 3.2+ */
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMCUiIHkyPSIxMDAlIj48c3RvcCBzdG9wLWNvbG9yPSIjMDBhYjk3IiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMCIvPjxzdG9wIHN0b3AtY29sb3I9IiMwMGFiOTciIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzAwYWI5NyIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjAiLz48c3RvcCBzdG9wLWNvbG9yPSIjMDBhYjk3IiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMSIvPjwvbGluZWFyR3JhZGllbnQ+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCN2c2dnKSIgLz48L3N2Zz4=);
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%,color-stop(0, rgb(0, 171, 151)),color-stop(0, rgb(0, 171, 151)),color-stop(0, rgb(0, 171, 151)),color-stop(1, rgb(0, 171, 151)));
    /* Android 2.3 */
    background-image: -webkit-repeating-linear-gradient(top,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 100%);
    /* IE10+ */
    background-image: repeating-linear-gradient(to bottom,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 100%);
    background-image: -ms-repeating-linear-gradient(top,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 100%);


    }
    @include iphone {
        width: 180px;
    }
}
.test__wrap-pic {
    position: absolute;
    top: 30px;
    left: 40%;
    z-index: 10;
    width: 280px;
    height: 130px;
    @include desktop {
        width: 100px;
        height: 60px;
        left: 43%;
    }
    @include tablets {
        top: 230px;
        left: 50%;
        transform: translateX(-50%) rotate(450deg);
        
    }
}
.strela-img {
    display: block;
    max-width: 100%;
    height: 100%;
}
.block__button-test {
    margin-top: 20px; 
    width: 100%;
   
   
}
.button__link-test {
    display: block;
    font-family: 'robotoCondensedBold';
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    border: 2px dashed rgb(81, 107, 120);
    text-decoration: none;
    width: 280px;
    height: 50px;
    margin: 0 auto;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-out;

    &:hover {
        color: $red_shade; 
        border: 2px dashed $red_shade; 
    }
}