@font-face {
    font-family: 'Gagalin';
    src: url('../fonts/gagalin-regular-webfont.woff2') format('woff2'),
         url('../fonts/gagalin-regular-webfont.woff') format('woff'),
         url('../fonts/gagalin-regular-webfont.svg#gagalinregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'bernier';
    src: url('../fonts/bernierregular-regular-webfont.woff2') format('woff2'),
         url('../fonts/bernierregular-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'fontawesome';
    src: url('../fonts/fontawesome-webfont.woff2') format('woff2'),
         url('../fonts/fontawesome-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotobold';
    src: url('../fonts/roboto-bold-webfont.woff2') format('woff2'),
         url('../fonts/roboto-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'robotobold_italic';
    src: url('../fonts/roboto-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/roboto-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'robotoitalic';
    src: url('../fonts/roboto-italic-webfont.woff2') format('woff2'),
         url('../fonts/roboto-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Roboto-light';
    src: url('../fonts/roboto-light-webfont.woff2') format('woff2'),
         url('../fonts/roboto-light-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;

}
@font-face {
    font-family: 'Roboto_light_italic';
    src: url('../fonts/roboto-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/roboto-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'Roboto-medium';
    src: url('../fonts/roboto-medium-webfont.woff2') format('woff2'),
         url('../fonts/roboto-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;

}
@font-face {
    font-family: 'Roboto_italic';
    src: url('../fonts/roboto-mediumitalic-webfont.woff2') format('woff2'),
         url('../fonts/roboto-mediumitalic-webfont.woff') format('woff');
     font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('../fonts/roboto-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}
@font-face {
    font-family: 'robotoCondensedBold';
    src: url('../fonts/robotocondensed-bold_0-webfont.woff2') format('woff2'),
         url('../fonts/robotocondensed-bold_0-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'robotoCondensedRegular';
    src: url('../fonts/robotocondensed-regular_0-webfont.woff2') format('woff2'),
         url('../fonts/robotocondensed-regular_0-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}