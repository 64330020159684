.genpass {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 70px;
}
.genpass__list {
    display: flex;
    align-items: center;
    flex-direction: column;
     @include phones {
        width: 100%;
    }
}
.genpass__item-input-pass {
    display: flex;
    margin-top: 20px;
    width: 100%;
    @include laptop {
        // display: block;
        align-items: center;
        flex-direction: column;
        
    } 
}
.genpass__item-pass,
.genpass__item-promo {
    width: 400px;
    min-height: 50px;
    height: auto;
    border: 7px;
    border: 2px solid #4A89DC;
    padding: 16px;
    background-color: #F5F7FA;
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-size: 16px;
    color: #656D78;

    @include phones {
        width: 100%;
    }
}
.genpass__item-wr {
    display: flex;  
    margin-top: 16px;
    @include laptop {
       
        justify-content: center;
    } 
}
.genpass__item-btn,
.genpass-promo__item-btn {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 10px;
    font-family: 'Roboto';
    font-size: 16px;
    color: #F5F7FA;
    background-color: #4A89DC;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    cursor: pointer;
    transition: background ease-in-out 0.3s;
    &:hover {
        background-color: lighten(#4A89DC, 3%);
    }
    
    
}
.genpass__item-copy,
.genpass-promo__item-copy {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 60px;
    background-color:#37BC9B;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    cursor: pointer;
    transition: background ease-in-out 0.3s;
    &:hover {
        background-color: lighten(#37BC9B, 5%);
    }
    
}
.genpass__item--svg {
    width: 30px;
    height: 30px;
    fill: #F5F7FA;
}
.genpass__item-control {
    margin-top: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.genpass__item-control-wrap{
    display: inline-flex;
    border: 2px solid #4A89DC;
    border-radius: 7px;
    margin-top: 10px;
}
.genpass__item-expression,
.genpass__item-count,
.genpass-promo__item-expression,
.genpass-promo__item-count{
    width: 45px;
    height: 45px;
    background-color: #F5F7FA;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.genpass__item-control-svg {
    fill:  #4A89DC;
    width: 25px;
    height: 25px;
}
.genpass__item-num,
.genpass__item__count-num,
.genpass-promo__item-num,
.genpass-promo__item__count-num {
    width: 45px;
    height: 45px;
    outline: none;
    border: none;
    background-color: #F5F7FA;
    border-left: 2px solid #4A89DC;
    border-right: 2px solid #4A89DC;
    font-family: 'robotobold';
    font-size: 25px;
    color: #656D78;
    text-align: center;  
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type='number'] {
    -moz-appearance: textfield;
}


.genpass__item-chk-wr {
    margin-top: 16px;
    display: inline-flex;
    flex-direction: column;
}
.genpass__item-label {
    margin-top: 16px;
    cursor: pointer;
    
}
.genpass__item-span {
    font-family: 'Roboto';
    font-size: 18px;
    color: #434A54;
    margin-left: 8px;
}
/* Скрываем реальный чекбокс */
.genpass__item-inp {
	display: none;
}

/* Задаем внешний вид для нашего кастомного чекбокса. Все обязательные свойства прокомментированы, остальные же свойства меняйте по вашему усмотрению */
.checkbox-custom {
	position: relative;      /* Обязательно задаем, чтобы мы могли абсолютным образом позиционировать псевдоэлемент внютри нашего кастомного чекбокса */
	width: 30px;             /* Обязательно задаем ширину */
	height: 30px;            /* Обязательно задаем высоту */
	border: 2px solid #4A89DC;
	border-radius: 3px;
}
/* Кастомный чекбокс и лейбл центрируем по вертикали. Если вам это не требуется, то вы можете убрать свойство vertical-align: middle из данного правила, но свойство display: inline-block обязательно должно быть */
.checkbox-custom,
.label {
	display: inline-block;
	vertical-align: middle;
}
/* Если реальный чекбокс у нас отмечен, то тогда добавляем данный признак и к нашему кастомному чекбоксу  */
.checkbox:checked + .checkbox-custom::before {
	content: "";             /* Добавляем наш псевдоэлемент */
	display: block;			 /* Делаем его блочным элементом */
	position: absolute;      /* Позиционируем его абсолютным образом */
	/* Задаем расстояние от верхней, правой, нижней и левой границы */
	top: 4px;
	right: 4px;
	bottom: 4px;
	left: 4px;
	background: #4A89DC;     /* Добавляем фон. Если требуется, можете поставить сюда картинку в виде "галочки", которая будет символизировать, что чекбокс отмечен */
	border-radius: 2px;
}
.checked {
    & + .checkbox-custom::before {
	content: "";             /* Добавляем наш псевдоэлемент */
	display: block;			 /* Делаем его блочным элементом */
	position: absolute;      /* Позиционируем его абсолютным образом */
	/* Задаем расстояние от верхней, правой, нижней и левой границы */
	top: 4px;
	right: 4px;
	bottom: 4px;
	left: 4px;
	background: #4A89DC;     /* Добавляем фон. Если требуется, можете поставить сюда картинку в виде "галочки", которая будет символизировать, что чекбокс отмечен */
	border-radius: 2px;
}
}
// tabs
.genpass__tabs-list {
    display: flex;
    justify-content: center;

    @include phones {
       flex-direction: column;
    }
}
.genpass__tabs-item {
   height: 40px; 
   border-right: 1px solid white;

    @include phones {
       border-right: none;
       border-bottom: 1px solid white;

      
    }

   &:last-child {
       border-right: none;
       border-bottom: none;
   }

   
}
.genpass__tabs-link {
    display: flex;
    align-items: center;
    height: 100%;
    color: white;
    font-family: 'Roboto';
    text-transform: uppercase;
    cursor: pointer;
    background: transparent;
    padding: 0 16px;
    background: $blue_title;
    transition: background .2s;

    @include phones {
       padding: 0;
       justify-content: center;
    }

    &:hover {
      background: lighten($blue_title, 3%);
   }
}
.genpass__promo__item-label {
    display: flex;
    flex-direction: column;
}
.genpass__promo-inp {
    width: 100%;
    height: 45px;
    outline: none;
    background-color: #F5F7FA;
    border: 2px solid #4A89DC;
    padding-left: 16px;
    font-family: 'robotobold';
    font-size: 25px;
    color: rgb(62, 76, 82);
    // color: rgb(24, 24, 224);
    margin-top: 10px;
    &[placeholder] {
        font-family: 'Roboto';
        font-size: 18px;
    }
}
.genpass__promo-span {
    font-family: 'Roboto';
}
.genpass__tabs-container {
    display: none;
}
.active-gener-cont {
    display: block;
}
.active-gener-tabs {
    background: $dark_green;
    &:hover {
      background: lighten($dark_green, 2%);
   }
}

