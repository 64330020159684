.section_about {
    height: 100%;
    
    background: url(../images/board1.jpg) no-repeat;
    background-size: cover;
    background-position: center;
     @include phones {
        height: auto;
    }
     
}
.container_about {
    height: 100%;
    width: 100%;
    position: relative;
    &:after {
        position: absolute;
        content: '';
        display: block;
        top: 20px;
        left: 80px;
        // transform: translateY(-50%);
        background: url(../images/slon1.png) no-repeat;
        background-size: cover;
        width: 280px;
        height: 500px;
        opacity: 0.7;
        @include phones {
            left: 50%;
            transform: translateX(-50%);
            width: 200px;
            height: 350px;
        }
    }
    &:before {
        position: absolute;
        content: '';
        display: block;
        top: 70%;
        right: 40px;
        transform: translateY(-50%);
        background: url(../images/pyton.png) no-repeat;
        background-size: cover;
        width: 280px;
        height: 350px;
        opacity: 0.7;
        @include phones {
            top:  inherit;  
            right: inherit;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            width: 230px;
            height: 300px;
        }
    }

    @include tablets {
        flex-direction: column;
    }
    @include phones {
        padding: 360px 0 300px;
    }
   
}

.block__title {
    width: 100%;
    height: 70px;
    background-color: $green;
    position: relative;
    overflow: hidden;
    @include laptop {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.block__title-wr {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @include laptop {
        position: relative;
        left: inherit;
        top: inherit;
        transform: inherit;
    }
}
.title {
    text-transform: uppercase;
    font-family: 'bernier';
    font-size: 40px;
    color: #fff;
    @include phones {
	 font-size: 30px;
    }
}
.title-svg {
    fill: #fff;
    margin-right: 10px;
    width: 45px;
    height: 45px;
    @include phones360 {
	    width: 40px;
        height: 40px;
    }
    
}
.title-svg--left {
    fill: rgb(97, 94, 94);
    @include tablets {
        padding-top: 20px;
    }
}
.about__text {
    font-family: 'Gagalin';
    font-size: 16px;
    padding: 15px;
    z-index: 10;
    border-radius: 5px;
    color: rgba(white, 0.8);
    position: absolute;
    box-sizing: padding-box;
}
.about__text-title {
    font-family: 'Gagalin';
    font-size: 16px;
    color: rgba($red_shade, 0.8);
    position: absolute;
}
.pos1 {
    width: 800px;
    height: 250px;
    top: 20px;
    right: 20%;
    font-size: 16px;
    padding: 100px 40px 40px;
     @media screen and (max-width: 1200px) {
       right: 10%;
    }
     @media screen and (max-width: 992px) {
        width: 90%;
        right: inherit;
        left: 50%;
        transform: translateX(-50%)

    }
     @media screen and (max-width: 768px) {
        font-size: 14px;
        top: 40px;
        padding: 80px 40px 40px;
    }
    @media screen and (max-width: 480px) {
        position: relative;
        padding: 0;
        height: auto;
        top: 0;
        margin-top: 40px;
    }
    .about__text-title {
        top: 40px;
        left: 43%;
        @media screen and (max-width: 768px) {
            left: 42%;
            top: 50px; 
        }
        @media screen and (max-width: 480px) {
            position: relative;
            display: block;
            top: 0;
            left: 0;
            margin-bottom:20px;
            text-align: center;
        }
}
    
}

.pos2 {
    top: 300px;
    right: 10%;
    width: 690px;
    height: 200px;
    padding: 80px 40px 40px;
    @media screen and (max-width: 800px) {
        width: 90%;
        right: inherit;
        left: 50%;
        transform: translateX(-50%);
    }
    @media screen and (max-width: 480px) {
        position: relative;
        top: 0;
        margin-top: 40px;
        padding: 0;
        height: auto;
    }
    .about__text-title {
        top: 10px;
        right: 20%;
        @media screen and (max-width: 800px) {
            right: 15%; 
      
        }
        @media screen and (max-width: 480px) {
            position: relative;
            display: block;
            top: 0;
            right: 0;
            margin-bottom:20px;
            text-align: center;
        }
    }
}
.pos3 {
    bottom: 25%;
    left: 80px;
    width: 650px;
    height: 180px;
    padding: 50px 40px 40px;
    
    @media screen and (max-width: 992px) {
        width: 80%;
        height: 180px;
        left: 50%;
        transform: translateX(-50%);
        
    }
    @media screen and (max-width: 480px) {
        position: relative;
        padding: 0;
        height: auto;
        top: 0;
        margin-top: 40px;
    }

    .about__text-title {
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        @media screen and (max-width: 480px) {
            position: relative;
            display: block;
            top: 0;
            right: 0;
            margin-bottom:20px;
            text-align: center;
        }
    }
}
.pos4 {
    bottom: 12%;
    left: 45%;
    width: 650px;
    height: 90px;
    padding: 35px 0 0 150px;
    @media screen and (max-width: 1700px) {
        left: 38%;
    }
    @media screen and (max-width: 1200px) {
        left: 50%;
        transform: translateX(-50%);
    }
    @media screen and (max-width: 768px) {
        width: 90%;
        padding: 35px 0 0 100px;
    }
    @media screen and (max-width: 600px) {
        width: 90%;
        padding: 35px 0 0 60px;
    }
    @media screen and (max-width: 480px) {
        position: relative;
        padding: 0;
        height: auto;
        top: 0;
        margin-top: 40px;
        
    }
}
.pos-img {
    display: block;
    max-width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @media screen and (max-width: 480px) {
        display: none;  
	}
}
.about__text__link {
    text-decoration: none;
    color: #72808D;
    border-bottom: 1px solid #72808D;
    position: relative;
    z-index: 10;
}