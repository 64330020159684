.container__comments {
    width: 100%;
    // border: 1px solid red;
    height: auto;
    margin-top: 15px;
}
.link__add-code {
    font-family: 'Roboto-light';
    font-size: 15px;
    color: $blue;
}
.form__comments,
.form__sub__comments {
    width: 100%;
    height: auto;  
}
.wrap__textarea,
.wrap__sub__textarea {
    position: relative;
}
.comments__textarea_text,
.comments__textarea__sub_text {
    width: 90%;
    outline: none;
    resize: none;
    height: 160px;
    background-color: lighten($yellowL, 8%);
    border-radius: 5px;
    color: $blue;   
}
.add__comments,
.add__sub__comments {
    height: 30px;
    cursor: pointer;
    border: none;
    outline: none;
    color: white;
    border-radius:6px;
    background-color: $blue_title;
    margin-top: 10px;
}
.block__comments,
.comments__list {
    width: 100%;
    height: auto;
}
.comments__item,
.comments__sub__item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}
.content__comments {
    width: 95%;
    margin-top: 20px;
}
.block__h2__title-comments {
    justify-content: flex-start;
    border-bottom: 2px solid $text_color;
    height: 65px;
    margin-top: 10px;
}
.comments__title {
    font-family: 'Roboto-medium';
    font-weight: 500;
    color: $blue_title;
}
.comments__date {
    font-family: 'Roboto';
    font-weight: 400;
    color: $text_color;
    margin-left: 5px;
    font-size: 14px
}
.comments__text {
    font-family: 'Roboto';
    font-weight: 400;
    color: $text_color;
}
.block__sub__comments {
    width: 100%;
}
.comments__reply,
.delete__comments,
.comments__show,
.delete__sub__comments {
    font-family: 'Roboto';
    font-weight: 400;
    color: $text_color;
    font-size: 14px;
    text-decoration: underline;
}
.comments__show {
    position: relative;
     &:after {
        content: "";
        position: absolute; 
        border: 4px solid transparent; 
        border-top: 8px solid $text_color;
        right: -11px;
        margin-top: 8px;
    }
}
.comments__show--up {
      &:before {
            content: "";
            position: absolute; 
            border: 4px solid transparent; 
            border-bottom: 8px solid $text_color;
            right: -11px;
            margin-top: 4px;
    }

        &:after {
            display: none;
        }
}
.delete__comments,
.comments__show {
    margin-left: 10px;
}
.wrap__sub__comments {
    width: 90%;
    margin-left: 10%;
    margin-top: 10px;
    display: none;
    transition: all 0.3s;
}
.wrap__form {
    width: 100%;
    display: none;
    transition: all 0.5s;
}

.complaint {
    width: 5%;
    position: relative;
}
.wrap__complaint-svg {
    margin-top: 25px;
 
}
.complaint-svg {
    width: 30px;
    height: 25px;
    cursor: pointer;
    fill: lighten($text_color, 20%);
    transition: all 0.2s;
}
.complaint__list {
    display: none;
    position: absolute;
    right: 50%;
    width: 220px;
    height: 60px;
    border: 1px solid $text_color;
    border-radius: 5px;
    background-color: $yellowD;
    box-shadow: -1px 0px 5px 0px rgba(0,0,0,0.75);
}
.complaint__item {
    width: 100%;
    height: 50%;
    border-bottom: 1px solid $text_color;
    text-align: center;
    cursor: pointer;
    padding-top: 2px;
    font-family: 'Roboto';
    color: $blue;
    transition: all 0.2s;
    &:last-child{
        border-bottom: none; 
    }
    &:hover {
         color: $red_shade;
    }
}
.complaint-svg--active {
    fill: $red_shade;
}
.complaint__list--active {
    display: block;
}
.wrap__form--active {
    display: block;
    
}
.wrap__sub__comments--active {
    display: block;

}
.default_message {
   font-family: 'Roboto';
   color: $text_color;
   margin-top: 10px;
}
.error__container--textarea {
    position: absolute;
    bottom: -38px;
    left: 120px;
    height: 30px;
    min-width: 250px;
    width: auto;
    background-color: #d82f2f;
    border-radius: 7px;
    color: #fff;
    font-size: 14px;
    font-family: "Roboto";
    padding-top: 4px;
    text-align: center;
    z-index: 20;

    &:before {
        content: "";
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #d82f2f;
        position: absolute;
        top: -30%;
        left: 10%;
    }
}
.link__default {
    font-family: 'Roboto';
    color: $blue_title;
}