.container__navigation {
    width: 98%;
    
}
.navigation {
    width: 100%;
    padding-top: 10px;
}
.navigation__link {
    text-decoration: none;
    border-bottom: 1px solid $dark;
    font-family: 'Roboto';
    color: $dark;
}
.navigation__span {
    font-family: 'Roboto';
    color: lighten($dark, 10%); 
}