$dark: #282e34;
$text_color: #495663;
$blue: #2c6077;
$yellowD: #e5da9e;
$yellowL: #efe7bb;
$green: #00bfa5;
$greffen: #006356;
$dark_green: #02aa99;
$red_shade: #d55a5a;
$blue_title:#4a7ebf;

$big_desktop: 1800px;
$desktop1550: 1550px;
$desktop1316: 1316px;
$desktop1050: 1050px;
$desktop: 1200px;
$laptop: 992px;
$laptop874: 874px;
$tablets: 768px;
$tablets640: 640px;
$tablets600: 600px;
$tablets400: 400px;
$phones:480px;
$phones360:360px;
$iphone: 320px;
