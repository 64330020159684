.auth__popup,
.forgot__popup {
    width: 420px;
    height: auto;
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    background: $yellowD;
    box-shadow: 0px 2px 13px 0px rgba(0,0,0,0.75);
    display: none;
    z-index: 30;
    @include phones {
        width: 98%;
    }
}

.auth__popup--active,
.forgot__popup--active {
    display: block;
}
.block__form {
    width: 80%;
}
.form__auth,
.form__forgot {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
.auth__input--login,
.auth__input--pass,
.forgot__input--mail{
    margin-bottom: 20px;
    height: 40px;
    outline: none;
    width: 100%;
    background-color: lighten($yellowL, 8%) !important;
    border-radius: 5px;
    color: $blue !important;
}
.auth__input--login[placeholder],
.auth__input--pass[placeholder],
.forgot__input--mail[placeholder] {
    font-size: 16px;
    font-family: 'Roboto';
    color: #5e6b6a !important;
    padding-left: 10px;
}
.auth__recaptcha {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.send__auth,
.send__mail-forgot {
     height: 35px;
    cursor: pointer;
    border: none;
    color: white;
    border-radius: 10px;
    background-color: #00ab97;
    margin-top: 10px;
    margin-bottom: 20px;
    /* IE9, iOS 3.2+ */
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMCUiIHkyPSIxMDAlIj48c3RvcCBzdG9wLWNvbG9yPSIjMDA5Njg4IiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMCIvPjxzdG9wIHN0b3AtY29sb3I9IiMwMGJmYTUiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzAwYmZhNSIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjAiLz48c3RvcCBzdG9wLWNvbG9yPSIjMDA5Njg4IiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMSIvPjwvbGluZWFyR3JhZGllbnQ+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCN2c2dnKSIgLz48L3N2Zz4=);
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%,color-stop(0, rgb(0, 150, 136)),color-stop(0, rgb(0, 191, 165)),color-stop(0, rgb(0, 191, 165)),color-stop(1, rgb(0, 150, 136)));
    /* Android 2.3 */
    background-image: -webkit-repeating-linear-gradient(top,rgb(0, 150, 136) 0%,rgb(0, 191, 165) 0%,rgb(0, 191, 165) 0%,rgb(0, 150, 136) 100%);
    /* IE10+ */
    background-image: repeating-linear-gradient(to bottom,rgb(0, 150, 136) 0%,rgb(0, 191, 165) 0%,rgb(0, 191, 165) 0%,rgb(0, 150, 136) 100%);
    background-image: -ms-repeating-linear-gradient(top,rgb(0, 150, 136) 0%,rgb(0, 191, 165) 0%,rgb(0, 191, 165) 0%,rgb(0, 150, 136) 100%);

     &:hover {
        background-color: #00ab97;
        /* IE9, iOS 3.2+ */
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMCUiIHkyPSIxMDAlIj48c3RvcCBzdG9wLWNvbG9yPSIjMDBhYjk3IiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMCIvPjxzdG9wIHN0b3AtY29sb3I9IiMwMGFiOTciIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzAwYWI5NyIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjAiLz48c3RvcCBzdG9wLWNvbG9yPSIjMDBhYjk3IiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMSIvPjwvbGluZWFyR3JhZGllbnQ+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCN2c2dnKSIgLz48L3N2Zz4=);
        background-image: -webkit-gradient(linear, 0% 0%, 0% 100%,color-stop(0, rgb(0, 171, 151)),color-stop(0, rgb(0, 171, 151)),color-stop(0, rgb(0, 171, 151)),color-stop(1, rgb(0, 171, 151)));
        /* Android 2.3 */
        background-image: -webkit-repeating-linear-gradient(top,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 100%);
        /* IE10+ */
        background-image: repeating-linear-gradient(to bottom,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 100%);
        background-image: -ms-repeating-linear-gradient(top,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 100%);

    }

    &:focus{outline: 0;}
}
.auth__close,
.forgot__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.auth__close-svg,
.forgot__close-svg {
    width: 18px;
    height: 18px;
    fill: #d82f2f;
}
.link-forgot_pass {
    text-align: center;
    text-decoration: underline;
    font-size: 16px;
    font-family: 'Roboto';
    color: $blue;
    margin-bottom: 20px;
}
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    color: #5e6b6a !important;
    background-color: lighten($yellowL, 8%) !important;
}