.block__profile {
    width: 80%;
    height: auto;
    margin: 0 auto;
    background-color: $yellowD;
    @include desktop1050 {
        width: 90%;
    }
    @include laptop {
        width: 98%;
    }
    @include laptop874 {
         overflow: hidden;
    }
}
.profile__shrt-text {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    font-family: 'robotobold';
    font-size: 18px;
    @include laptop874 {
        padding-left: 5px;
    }
}
.wrap__profile {
    width: 100%;
}
.profile__data {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    min-height: 70px;
    height: auto;
    border-top: 1px solid gray;

    &:hover {
        background-color: #ece2a7;
        .profile-svg {
            display: block;
        }
    }

    @include laptop874 {
        flex-direction: column;
        position: relative;
    }
}
.profile-title {
    width: 21%;
    padding-left: 20px;
    font-family: 'Roboto';
     @include laptop {
        width: 30%;
    }
    @include laptop874 {
         width: 98%;
         padding-left: 0;
    }
}
.profile-value {
    width: 75%;
    font-family: 'Roboto';
     @include laptop {
        width: 66%;
    }
    @include laptop874 {
         width: 98%;
    }
}
.block__profile-svg {
    width: 4%;
     @include laptop874 {
       position: absolute;
       right: 0;
       top: 50%;
       transform: translateY(-50%);
    }
    @include tablets600 {
       right: 3%;
    }
}
.profile-svg {
    fill: #d55a5a;
    display: none;
    @include laptop874 {
       display: block;
    }
}

.block__form-edit {
    width: 79%;
    height: 100%;
    display: none;
    @include laptop874 {
        width: 98%; 
    }
}
.form__edit {
    display: flex;
    align-items: center;
    height: 100%;
     @include laptop874 {
       flex-direction: column;
        align-items: inherit;
        width: 100%;
     }
}
.input__edit {
    height: 35px;
    outline: none;
    width: 64%;
    background-color: #f7f3dc;
    border-radius: 3px;
    margin-right: 20px;
    border: 1px solid #88aca8;
    @include laptop874 {
       width: 50%;
       margin-top: 10px;
     }
}
.input__edit--pass {
    width: 31%;
    @include laptop {
        width: 30%;
    }
     @include laptop874 {
       width: 50%;
    }
}
#passNewInput {
    margin-right: 16px;
     @include laptop {
        margin-right: 15px;
    }
}
.input__edit[value] {
    font-family: 'Roboto';
}
.input__edit[placeholder] {
    font-family: 'Roboto';
}
.save__edit {
    margin-right: 20px;
    height: 35px;
    width: 15%;
    cursor: pointer;
    border: none;
    color: white;
    border-radius: 7px;
    background-color: #00ab97;
     /* IE9, iOS 3.2+ */
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMCUiIHkyPSIxMDAlIj48c3RvcCBzdG9wLWNvbG9yPSIjMDA5Njg4IiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMCIvPjxzdG9wIHN0b3AtY29sb3I9IiMwMGJmYTUiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzAwYmZhNSIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjAiLz48c3RvcCBzdG9wLWNvbG9yPSIjMDA5Njg4IiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMSIvPjwvbGluZWFyR3JhZGllbnQ+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCN2c2dnKSIgLz48L3N2Zz4=);
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%,color-stop(0, rgb(0, 150, 136)),color-stop(0, rgb(0, 191, 165)),color-stop(0, rgb(0, 191, 165)),color-stop(1, rgb(0, 150, 136)));
    /* Android 2.3 */
    background-image: -webkit-repeating-linear-gradient(top,rgb(0, 150, 136) 0%,rgb(0, 191, 165) 0%,rgb(0, 191, 165) 0%,rgb(0, 150, 136) 100%);
    /* IE10+ */
    background-image: repeating-linear-gradient(to bottom,rgb(0, 150, 136) 0%,rgb(0, 191, 165) 0%,rgb(0, 191, 165) 0%,rgb(0, 150, 136) 100%);
    background-image: -ms-repeating-linear-gradient(top,rgb(0, 150, 136) 0%,rgb(0, 191, 165) 0%,rgb(0, 191, 165) 0%,rgb(0, 150, 136) 100%);

     &:hover {
        background-color: #00ab97;
        /* IE9, iOS 3.2+ */
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMCUiIHkyPSIxMDAlIj48c3RvcCBzdG9wLWNvbG9yPSIjMDBhYjk3IiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMCIvPjxzdG9wIHN0b3AtY29sb3I9IiMwMGFiOTciIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzAwYWI5NyIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjAiLz48c3RvcCBzdG9wLWNvbG9yPSIjMDBhYjk3IiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMSIvPjwvbGluZWFyR3JhZGllbnQ+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCN2c2dnKSIgLz48L3N2Zz4=);
        background-image: -webkit-gradient(linear, 0% 0%, 0% 100%,color-stop(0, rgb(0, 171, 151)),color-stop(0, rgb(0, 171, 151)),color-stop(0, rgb(0, 171, 151)),color-stop(1, rgb(0, 171, 151)));
        /* Android 2.3 */
        background-image: -webkit-repeating-linear-gradient(top,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 100%);
        /* IE10+ */
        background-image: repeating-linear-gradient(to bottom,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 100%);
        background-image: -ms-repeating-linear-gradient(top,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 100%);

    }

    &:focus{outline: 0;}
     @include laptop874 {
       width: 50%;
       margin-top: 10px;
     }
}
.cancel__edit {
    text-decoration: none;
    font-family: 'Roboto';
    color: $dark;
    display: block;
    &:hover {
        color: #d55a5a;
        text-decoration: underline;
    }
     @include laptop874 {
       text-align: center;
       width: 50%;
       margin-top: 10px;
       margin-bottom: 10px;
     }
}
.block__form__edit--show {
    display: block;
    position: relative;
}
.profile--hide {
    display: none;
}
.error__container {
    font-family: 'Roboto';
    color: #d55a5a;
    font-size: 13px
}
