@mixin circle ($width, $color) {
	width: $width;
	height: $width;
	border-radius: $width/2;
	background-color: $color;
}
// responsive
@function rem($px) {
	@return $px / 16px+0rem;
}
@mixin cover() {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
@mixin big_desktop {
	@media screen and (max-width: $big_desktop) {
		@content;
	}
}
@mixin desktop {
	@media screen and (max-width: $desktop) {
		@content;
	}
}
@mixin desktop1550 {
	@media screen and (max-width: $desktop1550) {
		@content;
	}
}
@mixin desktop1316 {
	@media screen and (max-width: $desktop1316) {
		@content;
	}
}
@mixin desktop1050 {
	@media screen and (max-width: $desktop1050) {
		@content;
	}
}
@mixin laptop {
	@media screen and (max-width: $laptop){
		@content;
	}
}
@mixin laptop874 {
	@media screen and (max-width: $laptop874){
		@content;
	}
}
@mixin tablets {
	@media screen and (max-width: $tablets){
		@content;
	}
}
@mixin tablets600 {
	@media screen and (max-width: $tablets600){
		@content;
	}
}
@mixin tablets400 {
	@media screen and (max-width: $tablets400){
		@content;
	}
}
@mixin tablets640 {
	@media screen and (max-width: $tablets640){
		@content;
	}
}
@mixin phones {
	@media screen and (max-width: $phones){
		@content;
	}
}
@mixin phones360 {
	@media screen and (max-width: $phones360){
		@content;
	}
}
@mixin iphone {
	@media screen and (max-width: $iphone){
		@content;
	}
}