/* стили для новостей*/

.codetext{
    border: 1px inset;
    font-family: 'roboto';
	font-size: 15px;
    padding: 5px !important;
    background-color: #2b3a42;
    color: #62b1d8;
    width: 100%;
    @include tablets600 {
        overflow: scroll;
    }
    
} 

.h1title {
	font-family: 'robotobold';
	text-align: center;
	padding-top: 10px;
	font-size: 23px;
	color: #ef1c1c;
}
.red_obz {
  color: #ef1c1c;
  font-family: 'Roboto';
}
.green_obz {
  color: #2e932e;
  font-family: 'Roboto';
}

.nomer {
	color: #ef1c1c;
	font-family: 'Roboto';
	font-size: 20px;
	border: 2px solid #e87306;
	padding-left: 7px;
	padding-right: 7px;
    padding-bottom: 2px;
	border-radius: 7px;
	background: white;
	margin-right: 8px;
}
.h2title {
	font-family: 'robotobold';
	text-align: center;
	padding-top: 10px;
	font-size: 20px;
	color: #ef1c1c;
}
.p_title {
    font-family: 'robotobold';
	padding-top: 10px;
	font-size: 18px;
	text-indent: 25px;
	text-decoration: underline;
}
.images {
    // margin-top: 15px;
    max-width: 100%;
}
/*стиль для картинки Ворд пресса*/
.images-wordpress {
    @include tablets600 {
        max-width: 100%;
    }
}
/*конец*/
.span_bold {
    font-family: 'robotobold';
    color: $dark_green;
	font-size: 19px;
}
.span_bold_black {
    font-family: 'robotobold';
    color: $dark;
    font-size: 18px;
}
.span_bold_roz {
    font-family: 'robotobold';
    color:#dd29ac;
}
.span_bold_red {
    font-family: 'robotobold';
    color:#eb0024;
    font-size: 19px;
}
.spanSvoistvaCSS {
    font-family: 'robotobold';
    color:#dd29ac;
    font-size: 21px;
}
.p_bold {
    font-family: 'robotobold';
	padding-top: 10px;
	font-size: 18px;
	text-indent: 25px;
}
.class_link {
    font-family: 'Roboto-medium';
    font-size: 17px;
    color: #1561ba;
    border-bottom: 1px solid  #1561ba;
}
/* конец стилям для новостей*/
#news_titleh2,
.news_titleh2{
    font-family: 'Roboto-medium';
	text-align: center;
	padding-top: 10px;
	font-size: 20px;
	color: #103140;
}
#news_title {
	margin-top: 10px;
}

#news_title li a {
	display: block;
    min-height: 100px;
    margin-bottom: 25px;
    overflow: hidden;
	cursor: pointer;
  
}

#news_title li a:hover  {
-webkit-box-shadow: 2px 2px 29px 0px rgba(50, 50, 50, 0.51);
-moz-box-shadow:    2px 2px 29px 0px rgba(50, 50, 50, 0.51);
box-shadow:         2px 2px 29px 0px rgba(50, 50, 50, 0.51);
 background: #e5da9e;

}
.transition:not(:hover) {
  transition: 0.5s;
}
.img_news_grid {
    width: 50px;
    height: 50px;
	float: left;
}
#news_title li p {
	text-indent: 0px;
}
.discription_news_grid {
    width: 670px; 
    min-height: 50px;
    height: auto;
	float: right;
}

.title_news {
    display: block;
    font-family: 'robotobold';
    font-size: 18px;
    color: #F00E29;
  
	
}

.discription_news {
   display: block;
   text-decoration: none;
   font-family: 'Roboto-medium';
   font-size: 14px; 
   color:#3D3D3D;
   margin-top: -4px;

}
//  стили для таблицы
.table {
  margin-top: 30px;
  @include desktop1050 {
      display: none;
  }
}
.table table   {
    border: 2px solid #8c9aa0;
}
.table td   {
    border: 2px solid  #a4b8c1;
    text-align: center;
}
.table th   {
    border: 2px solid  #8c9aa0;
    background: #d4d6d6;
}
.table caption{
    margin-left: 245px;
    margin-bottom: 10px;
    font-family: 'robotobold';
    font-size: 19px;
    color: #35363e;
}
.table-head {
    text-align: center;
    padding: 9px;
    font-family: 'robotobold';
    font-size: 16px;
    color: #4b5458;
}
.table-left {
    padding: 4px;
    text-align: center;
    font-family: 'robotobold';
    font-size: 15px;
     color: #337083;
}

//  конец стилям для таблицы
.page-link {
    margin-top: 10px;
    padding-bottom: 20px;
}
.ul-link {
    margin-top: 10px;
    margin-left: 30px;
}
.ul-link li {
   margin-bottom: 7px;
}
.ul-link li a {
    font-family: 'Roboto-medium';
    font-size: 17px;
    color: #1561ba;
    border-bottom: 1px solid  #1561ba;
}
.copy {
	background-color: white;
}
.name-author {
	font-family: 'Roboto-medium';
    font-size: 20px;
}
.wrapper__img--news {
    @include tablets600 {
        width: 100%;
    }
    
}
.box__img {
    width: 100%;
    text-align: center;
}
.img__news-style {
    max-width: 100%;
}
.style__video {
    width: 560px;
    height: 300px;

    @include laptop874 {
        width: 460px;
        height: 250px;
    }
     @include laptop874 {
        width: 460px;
        height: 250px;
    }
     @include laptop874 {
        width: 460px;
        height: 250px;
    }
     @include tablets600 {
        width: 400px;
        height: 250px;
    }
      @include phones {
        width: 290px;
        height: 200px;
    }
}
.wrap__iframe {
    width: 100%;
    display: flex;
    justify-content: center;
}