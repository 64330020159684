$headerHeight: 140px;
// $headerHeight: 190px;
$angle: 15deg;
$gap: 10px;
$clip: 15px;
.section__header--home {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #1d3740;
    @media screen and (max-width: 1250px) {
        height: auto;
	}
}
.container_home {
    width: 98%;
    height: 100%;
    display: flex;
    position: relative;
    @include laptop874 {
        flex-direction: column;
        justify-content: center;
        align-content: center;
	}
}
.first-screen-wr {
    display: flex;
    margin-top: 70px;
    @media screen and (max-width: 1250px) {
        display: block;
	}
}
.first-screen-left,
.first-screen-right {
    width: 50%;
    @media screen and (max-width: 1250px) {
        width: 100%;
	}
}
.first-screen-left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 20;
    @media screen and (max-width: 1450px) {
        justify-content: flex-start;
    }
    @media screen and (max-width: 1250px) {
        flex-direction: row;
        justify-content: center;
	}
}
.first-screen__text {
    transition: all 0.6s ease-out;
    transition-delay: 0.5s;
}

.first-screen__text-wrap1 {
    line-height: 1.2;
    cursor: pointer;
    position: relative;

    transition: all 0.6s ease-out;
    transition-delay: 0.5s;

    display: block;
    @include phones {
        display: none;
    }
}
.first-screen__text-wrap2 {
    font-family: 'robotoCondensedRegular';
    line-height: 1.4;
    font-size: 30px;
    color: rgb(230,68,55);
    margin-top: 55px;
    margin-left: 30px;
    transition: all 0.6s cubic-bezier(0.555, -0.375, 0.000, 1.615);
    @include phones {
        font-size: 20px;
        margin-left: 0;
        text-align: center;
        margin-top: 15px;
    }
}
.first-screen__text-wrap3 {
    font-family: 'robotoCondensedRegular';
    font-size: 20px;
    color: rgb(214, 197, 120);
    margin-top: 16px;
    text-align: center;
    position: relative;
    left: -30px;
    @media screen and (max-width: 1250px) {
        left: 0;
    }
    @include tablets {
       padding: 0 15px
    }
    @include phones {
        font-size: 16px;
    }
}
.text-hyphen {
    font-weight: 300;
    font-size: 70px;
    color: rgb(230,68,55);
    position: absolute;
    top: -30px;
    left:124px;
    margin-left: $gap/2;

    transform: scale(1.6);
    z-index: 5;
    transition: all 0.6s cubic-bezier(0.555, -0.375, 0.000, 1.615);
}
.text-web {
    font-family: 'robotoCondensedBold';
    font-size: 60px;
    color: $dark_green;
    top: 50%;
    margin-top: -$headerHeight;
    position: absolute;

    width: 284px;
    height: 283px;
    overflow: hidden;
    transform: rotateZ($angle);
    
    text-align: right; 
    direction: rtl;
    margin-left: -$headerHeight - $gap + 1px;
    .i {
      text-indent: -$clip;
    }
     transition: all 0.6s cubic-bezier(0.555, -0.375, 0.000, 1.615);
} 
.text-dev {
    font-family: 'robotoCondensedBold';
    font-size: 60px;
    color: $dark_green;

    top: 50%;
    margin-top: -$headerHeight;
    position: absolute;

    width: 310px;
    height: 283px;
   
    overflow: hidden;
    transform: rotateZ($angle);
    transition: all 0.6s cubic-bezier(0.555, -0.375, 0.000, 1.615);
    margin-left: $headerHeight + $gap;
    .i { 
      text-indent: -$clip;
    }
   
}  
.i {
    font-style: normal;
    top: 0;
    left: 0;
    position: absolute;

    width: $headerHeight*2;
    height: $headerHeight*2;
    line-height: $headerHeight*2;
    transform: rotateZ(-$angle);
    transition: all 0.6s cubic-bezier(0.555, -0.375, 0.000, 1.615);
}      

.first-screen__text-open {
    .text-web ,
    .text-dev {
      i {
        text-indent: $clip;
      }
    }

    .first-screen__text-wrap2 {
        color: #6d5351;
    }

    .text-hyphen {
      transform: scale(1.6) rotateZ( -$angle );
    }
    .text-hyphen-mob {
        color: #6d5351;
    }
}
.first-screen__text-wrap-mobile {
    display: none;
    @include phones {
        display: block;
        text-align: center;
    }
}
.text-web-mob,
.text-dev-mob {
    font-family: 'robotoCondensedBold';
    font-size: 40px;
    color: $dark_green;
}
.text-hyphen-mob {
    font-size: 40px;
    color: rgb(230,68,55);
    transition: all 0.6s cubic-bezier(0.555, -0.375, 0.000, 1.615);
}
.first-screen-right {
    @media screen and (max-width: 1250px) {
        display: flex;
        justify-content: center;
	}
}
.parallax {
    position: relative;
    width: 800px;
    height: 550px;
    @media screen and (max-width: 1250px) {
        margin: 20px 0;
	}
    @include tablets {
        display: none;
    }
    @media screen and (max-width: 1250px) {
        width: 850px;
	}
}
.parallax-layer1 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -200px;
    z-index: 10;
    @media screen and (max-width: 1250px) {
        left: 0;
        height: 520px;
	}
}
.parallax-layer2 {
    width: 100%;
    height: 432px;
    position: absolute;
    top: 20px;
    left: -78px;
    z-index: 0;
    overflow: hidden;
    @media screen and (max-width: 1250px) {
        left: 10px;
	}
}
.parallax-img-wr {
    width: 100%;
    height: 3312px;
    animation: bg 30s linear infinite;
}
.parallax-img1 {
    display: block;
    min-width: 100%;
    height: 100%;
    @media screen and (max-width: 1250px) {
       min-width: inherit;
       max-width: 100%;
	}
}
@keyframes bg {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-1090px);
    }
}
.mobile-default {
    display: none;
    @include tablets {
        display: block;
        width: 400px;
        margin: 20px 0;
    }
    @include phones {
        width: 340px;
    }
    @include phones360 {
        width: 295px;
    }
}
.mobile-default-img {
    display: block;
    max-width: 100%;
}
.menu__mobile{
    display: none;
    @include tablets {
        display: block;
        text-align: center;
	}
}
.menu__mobile--link {
    text-decoration: none;
    display: inline-block;
    width: 90px;
    height: 30px;
    border-radius: 6px;
    background-color: #FFFDD0;
    padding-top: 4px;
    margin-bottom: 20px;
    font-family: 'robotobold';
    color: #A52A2A;
    box-shadow: 1px 0px 13px 1px rgba(0,0,0,0.75);
    transition: all .3s;

    &:hover {
        color: rgb(177, 85, 85);
    }
}
.menu__mobile--link--active {
    box-shadow: inherit;
}