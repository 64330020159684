.menu_mobile {
    display: none;
    @include tablets {
        width: 310px;
        height: 400px;
        border-radius: 8px;
        box-shadow: 0px -2px 49px 0px rgba(0,0,0,0.75);
        position: absolute;
        top: 105px;
        left: 50%;
        transform: translateX(-50%);
        background-color: $yellowL;
        z-index: 30;

        flex-direction: column;
        justify-content: center;
        align-items: center;
	}
}
.menu_mobile__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.menu_mobile__item {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}
.menu_mobile__link {
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'bernier';
    color: $dark_green;
    font-size: 30px;
    position: relative;
    &:before {
        content: '';
        display: block;
        width: 20px;
        height: 3px;
        background-color: $dark_green;
        position: absolute;
        top: 50%;
        left: -30px;
        transform: translateX(-50%);
    }
     &:after {
        content: '';
        display: block;
        width: 20px;
        height: 3px;
        background-color: $dark_green;
        position: absolute;
        top: 50%;
        right: -50px;
        transform: translateX(-50%);
        
    }

    &:hover {
        color: $green;
        &:after {
            background-color: $green;
        }
          &:before {
            background-color: $green;
        }
    }
}
.menu_mobile--visible {
    display: flex;
}