.block__list__account {
    position: absolute;
    top: 60px;
    right: 0;
    width: 150px;
    height: 80px;
    background-color: rgba($yellowL, 0.8);
    box-shadow: -1px 2px 5px 0px rgba(0,0,0,0.75);
    display: none;
    border-radius: 5px;
    
    &:before {
        content: "";
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 13px solid rgba($yellowL, 0.8);
        position: absolute;
        top: -13px;
        right: 4px;
    }

    @include tablets {
        top: 104px;
        right: 18%;
    }
     
}
.section__header--home {
    .block__list__account {
        background-color: $dark_green;
        opacity: 0.9;
        &:before {
             border-bottom: 13px solid $dark_green;
        }
       
    }
    .account__link {
        color: white;
        &:hover {
            color: $dark;
            border-bottom: none;
        }
    }
}
.account__list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.account__item {
    margin-top: 5px;

    &:first-child {
        margin-top: 0;
    }
}
.account__link {
    text-decoration: none;
    font-family: 'robotobold';
    font-size: 18px;
    color: $blue;
    transition: all .2s;
    &:hover {
        border-bottom: 2px solid $blue;
    }
}
.block__list__account--active {
    display: block;
    z-index: 100;
    @include tablets600 {
        display: none;
	}
}