.menu__item {
   position: relative;
}

.menu__link-count {
    position: absolute;
    left: -22px;
    top:-5px;
    // transform: translateY(-50%);
    color: white;
    font-family: 'Roboto';
    font-size: 15px;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #e64437;
    box-shadow: -1px 2px 5px 0px rgba(0,0,0,0.75);
}

.sub-menu__list {
    display: none;
    position: absolute;
    top: 40px;
    right: 0;
    width: 250px;
    padding: 15px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(239,231,187,0.8);
    box-shadow: -1px 2px 5px 0px rgba(0,0,0,0.75);
    border-radius: 5px; 
    opacity: 0.9;
    z-index: 100;
    &:before {
        content: "";
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 13px solid rgba(239,231,187,0.8);
        position: absolute;
        top: -13px;
        right: 4px;
    }
}
.sub-menu-toggle {
    display: flex;
}
.sub-menu__item {
    margin-top: 5px;
    &:first-child {
        margin-top: 0;
    }
}
.sub-menu__link {
    text-decoration: none;
    font-family: 'robotobold';
    font-size: 18px;
    color: #2c6077;
    transition: all .2s;
    &:hover {
        color: $dark;
    }

}
.section__header--home {
    .sub-menu__link {
        color: white;
       
    }
    .sub-menu__list {
        background-color: #02aa99;
        &:before {
            border-bottom: 13px solid #02aa99;
        }
    }
}
