.margin-left {
    margin-left: 5px;
}
.section_contacts {
    min-height: 800px;
    height: auto;
    
}
.container__contacts {
    width: 600px;
    @include tablets {
        width: 98%;   
    }
}
.block__form {
    width: 40%;
    height: auto;
    margin: 0 auto;
    @include laptop {
        width: 60%;
    }
    @include tablets600 {
        width: 70%;
    }
    @include phones {
        width: 80%;
    }
}
.form__feedback {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
.wrapper__input--name,
.wrapper__input--email,
.wrapper__input--text {
    width: 100%;
    position: relative;
}
.feedback__input--name,
.feedback__input--email{
    margin-bottom: 20px;
    height: 40px;
    outline: none;
    width: 100%;
    background-color: lighten($yellowL, 8%);
    border-radius: 5px;
    color: $blue;
}
.feedback__input--text {
    width: 100%;
    margin-bottom: 20px;
    outline: none;
    resize: none;
    height: 150px;
    background-color: lighten($yellowL, 8%);
    border-radius: 5px;
    color: $blue;
}
    
.feedback__input--name[placeholder],
.feedback__input--email[placeholder],
.feedback__input--text[placeholder] {
    font-size: 16px;
    font-family: 'Roboto';
    color: #5e6b6a;
    padding-left: 10px;
}
.error__container--name,
.error__container--email,
.error__container--text {
    position: absolute;
    top: 60%;
    height: 30px;
    min-width: 250px;
    width: auto;
    background-color: #d82f2f;
    border-radius: 7px;
    color: #fff;
    font-size: 14px;
    font-family: "Roboto";
    padding-top: 4px;
    text-align: center;
    z-index: 20;

    &:before {
        content: "";
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #d82f2f;
        position: absolute;
        top: -30%;
        left: 10%;
    }
}
.error__recaptcha {
     background-color: #d82f2f;
     color: #fff;
     font-family: "Roboto";
     width: 100%;
     height: 30px;
     margin-bottom: 20px;
     border-radius: 7px;
     padding-top: 3px;
     padding-left: 5px;
}
.true__massage {
    background-color: $green;
     color: #fff;
     font-family: "Roboto";
     width: 100%;
     height: 30px;
     margin-bottom: 20px;
     border-radius: 7px;
     padding-top: 3px;
}
.send__feedback {
    height: 35px;
    cursor: pointer;
    border: none;
    color: white;
    border-radius: 10px;
    background-color: #00ab97;
    margin-top: 20px;
    /* IE9, iOS 3.2+ */
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMCUiIHkyPSIxMDAlIj48c3RvcCBzdG9wLWNvbG9yPSIjMDA5Njg4IiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMCIvPjxzdG9wIHN0b3AtY29sb3I9IiMwMGJmYTUiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzAwYmZhNSIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjAiLz48c3RvcCBzdG9wLWNvbG9yPSIjMDA5Njg4IiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMSIvPjwvbGluZWFyR3JhZGllbnQ+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCN2c2dnKSIgLz48L3N2Zz4=);
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%,color-stop(0, rgb(0, 150, 136)),color-stop(0, rgb(0, 191, 165)),color-stop(0, rgb(0, 191, 165)),color-stop(1, rgb(0, 150, 136)));
    /* Android 2.3 */
    background-image: -webkit-repeating-linear-gradient(top,rgb(0, 150, 136) 0%,rgb(0, 191, 165) 0%,rgb(0, 191, 165) 0%,rgb(0, 150, 136) 100%);
    /* IE10+ */
    background-image: repeating-linear-gradient(to bottom,rgb(0, 150, 136) 0%,rgb(0, 191, 165) 0%,rgb(0, 191, 165) 0%,rgb(0, 150, 136) 100%);
    background-image: -ms-repeating-linear-gradient(top,rgb(0, 150, 136) 0%,rgb(0, 191, 165) 0%,rgb(0, 191, 165) 0%,rgb(0, 150, 136) 100%);

     &:hover {
        background-color: #00ab97;
        /* IE9, iOS 3.2+ */
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMCUiIHkyPSIxMDAlIj48c3RvcCBzdG9wLWNvbG9yPSIjMDBhYjk3IiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMCIvPjxzdG9wIHN0b3AtY29sb3I9IiMwMGFiOTciIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzAwYWI5NyIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjAiLz48c3RvcCBzdG9wLWNvbG9yPSIjMDBhYjk3IiBzdG9wLW9wYWNpdHk9IjEiIG9mZnNldD0iMSIvPjwvbGluZWFyR3JhZGllbnQ+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCN2c2dnKSIgLz48L3N2Zz4=);
        background-image: -webkit-gradient(linear, 0% 0%, 0% 100%,color-stop(0, rgb(0, 171, 151)),color-stop(0, rgb(0, 171, 151)),color-stop(0, rgb(0, 171, 151)),color-stop(1, rgb(0, 171, 151)));
        /* Android 2.3 */
        background-image: -webkit-repeating-linear-gradient(top,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 100%);
        /* IE10+ */
        background-image: repeating-linear-gradient(to bottom,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 100%);
        background-image: -ms-repeating-linear-gradient(top,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 0%,rgb(0, 171, 151) 100%);

    }

    &:focus{outline: 0;}
}
.feedback__recaptcha {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.block__massage {
    width: 100%;
    display: flex;
    text-align: center;
    margin-top: 10px;
}
